class AudioService {
    
    constructor(audioSrc) {
        this.audio = null;
        this.init(audioSrc);
    }

    init(audioSrc) {
        this.audio = new Audio(audioSrc);
        this.audio.muted = true;
        this.audio.loop = true;
    }

    play() {
        if (this.audio) {
            this.audio.muted = false;
            this.audio.play().catch(error => {
                console.error('Error playing sound:', error);
                if (this.audio) this.audio.muted = true;
            });
        }
    }

    mute() {
        if (this.audio) {
            this.audio.muted = true;
        }
    }

    unmute() {
        if (this.audio) {
            this.audio.muted = false;
        }
    }

    stop() {
        if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
        }
    }
}

export const audioService = new AudioService('/alert2.mp3');