export default {
    new: 'Nuovo Evento',
    eventID: 'ID Evento',
    motivationID: 'Motivazione',
    motivationText: 'Motivazione Personalizzata',
    imei: 'Imei GPS',
    mode: 'Modalità',
    eventsData: {
        title: "Dati dell'evento",
        name: "Nome",
        Name: "Nome",
        eventName: "Nome dell'evento",
        timestamp: "Data e ora dell'evento",
        imei: "Imei gps",
        speed: "Velocità",
        address: "Indirizzo",
        userID: "Id utente",
        lastName: "Cognome",
        LastName: "Cognome",
        contactNumber1: "Numero di contatto 1",
        contactNumber2: "Numero di contatto 2",
        email: "Email",
        created_at: "Creato il",
        updated_at: "Aggiornato il",
        user_id: "Id utente",
        lat: "Latitudine",
        lng: "Longitudine",
        direction: "Direzione",
        altitude: "Altitudine",
        eventId: "Id dell'evento",
        event: "Evento",
        dt_tracker: "Data dispositivo Gprs",
        eventID: "Event Id",
        motivationID: 'Motivazione',
        motivationText: 'Motivazione Personalizzata',
        operatorName: 'Operatore',
        operatorLastName: 'Cognome Operatore',
        operatorUsername: 'Username Operatore',
        operatorCell: 'Numero di contatto Operatore',
        operatorEmail: 'Email Operatore',
        operatorUserType: 'Tipo di utente',
    },
    eventTypes: {
        all: 'Tutti',
        active: 'In corso',
        terminated: 'Terminati',
        suspended: 'Sospesi'
    }
}