<template>
    <section class="h-full flex flex-col">

        <!-- Intestazione -->
        <div class="py-1.5 px-2 text-slate-50 rounded-t-lg bg-surface-800 dark:bg-slate-50 dark:text-surface-800">
            <div class="flex items-center justify-start gap-2 text-sm">
                <span class="material-symbols-outlined material-symbols-font-300"> chat </span>
                <span class="font-bold tracking-tight uppercase">
                    Eventi in Entrata:
                    <span class="text-sm ml-1 px-2 py-.5 rounded-full border border-red-200 animate-pulse duration-500">
                        {{ notAssignedEvents.length }}
                    </span>
                </span>
            </div>
        </div>

        <!-- Contenuto -->
        <div class="flex-grow overflow-y-auto overflow-x-hidden px-3 py-1.5 mt-2 space-y-2">

            <!-- Se non ci sono eventi in entrata -->
            <template v-if="!notAssignedEvents || notAssignedEvents.length === 0">
                <div class="text-center text-slate-500 dark:text-slate-400 h-full flex items-center justify-center text-lg lg:text-3xl">
                    Nessun evento in entrata
                </div>
            </template>

            <!-- Modalità lista -->
            <template v-else>
                <TransitionGroup name="event-list" tag="div">
                    <div 
                        v-for="event in notAssignedEvents" :key="event.eventData.eventID"
                        class="bg-gradient-to-br from-white to-gray-100 dark:from-gray-800 dark:to-gray-900 transition-all duration-300 rounded-lg shadow-lg p-4 mb-3 hover:shadow-2xl hover:scale-102 relative overflow-hidden event-list-item"
                    >
                        <div class="absolute inset-0 border-2 border-indigo-400 dark:border-indigo-600 rounded-lg animate-pulse"></div>
                        <div class="flex flex-col items-start justify-between">
                        
                            <!-- Dati dell'evento -->
                            <div class="flex-1 min-w-0 mb-3 w-full">
                                
                                <!-- Numero dell'evento e data -->
                                <div class="flex items-center justify-between mb-2">
                                    <span class="text-lg font-bold text-indigo-600 dark:text-indigo-400"> Evento n° {{ event.eventData.eventID }} </span>
                                    <span class="text-sm bg-gradient-to-r from-indigo-500 to-purple-600 dark:from-indigo-400 dark:to-purple-500 text-white px-3 py-1 rounded-full shadow-md">
                                        {{ formatterDate(event?.eventData?.dt_tracker, 'messageDate') }}
                                    </span>
                                </div>

                                <!-- Nome del cliente (company)-->
                                <p v-if="event?.gsUserData && event?.gsUserData[0]" class="text-xl font-semibold uppercase mb-2 truncate text-gray-800 dark:text-gray-200">
                                    {{ event?.gsUserData[0]?.company }}
                                </p>

                                <!-- Indirizzo del dispositivo -->
                                <p class="text-sm truncate flex items-center text-gray-600 dark:text-gray-300">
                                    <span class="material-symbols-outlined text-indigo-500 dark:text-indigo-400 mr-2">location_on</span>
                                    {{ event.eventData.address }}
                                </p>

                            </div>

                            <!-- Bottone di accettazione -->
                            <Button 
                                icon="pi pi-check"
                                label="Accetta"
                                fluid
                                class="mt-2 !bg-gradient-to-r !border-none !from-emerald-500 !to-teal-600 hover:!from-emerald-600 hover:!to-teal-700 !text-white !font-semibold !transition-all !duration-600 !transform hover:!scale-95"
                                aria-label="Accetta"
                                @click="requestAcceptEvent(event.eventData.eventID)"
                            />

                        </div>
                    </div>
                </TransitionGroup>
            </template>

        </div>

    </section>

</template>

<script setup>

    // base imports
    import { TransitionGroup, computed, onUnmounted, onMounted } from 'vue';

    // services imports
    import { formatterDate } from '@/helpers/utils';
    import { handleErrorsSilent } from '@/helpers/errorHandler';
    import { audioService } from '@/services/AudioService';

    // store imports
    import { useAuthStore } from '@/stores/auth.js';
    import { useSocketStore } from '@/stores/socket';

    // recupero SocketStore
    const SocketStore = useSocketStore();

    // recupero i dati dell'utente
    const userData = computed(() => useAuthStore().getUser);

    const emits = defineEmits(['acceptEvent']);

    // definisco le props
    const props = defineProps({
        notAssignedEvents: {
            type: Array,
            required: true,
            validator: (value) => {
                return value.every(event => event.eventData && event.eventData.eventID);
            }
        },
        mode: {
            type: String,
            default: 'list',
            validator: (value) => {
                return ['list', 'carousel'].includes(value);
            }
        }
    });

    // funzione per richiedere l'accettazione di un evento
    const requestAcceptEvent = handleErrorsSilent(async (eventId = null) => {

        // controllo che eventId sia un numero valido
        if (!eventId || isNaN(eventId)) {
            throw new Error('eventId not found');
        }

        // controllo che eventId sia presente nell'array delle props
        if (!props.notAssignedEvents.some(event => parseInt(event.eventData.eventID) === parseInt(eventId))) {
            throw new Error('eventId not found');
        }

        // recupero l'evento dall'array delle props
        const event = props.notAssignedEvents.find(event => parseInt(event.eventData.eventID) === parseInt(eventId));

        // controllo che l'evento sia un oggetto valido
        if (!event || typeof event !== 'object' || Object.keys(event).length === 0) {
            throw new Error('event not found');
        }

        const { eventData = null } = event;
        
        // controllo che esista event.eventData
        if (!eventData || typeof eventData !== 'object' || Object.keys(eventData).length === 0) {
            throw new Error('eventData not found');
        }

        // controllo che eventData.imei sia presente e sia una stringa
        if (!eventData?.imei || typeof eventData?.imei !== 'string') {
            throw new Error('eventData.imei not found');
        }

        // setto l'oggetto
        const objToSend = {
            eventId: eventId || null,
            imei: eventData?.imei || null,
            operatorName: userData?.value?.operatorName || null
        };

        // controllo che tutte le chiavi di objToSend siano presenti e non null
        if (!objToSend.eventId || !objToSend.imei || !objToSend.operatorName) {
            throw new Error('objToSend not found');
        }

        // invio la richiesta per accettare l'evento
        SocketStore.emit('eventRequestAccept', objToSend);
    });

    // funzione in seguito all'accettazione di un evento
    const eventRequestAccept = handleErrorsSilent(async (values = null) => {

        // stoppo l'audio
        audioService.stop();

        // controllo che values sia un oggetto valido
        if (!values || typeof values !== 'object' || Object.keys(values).length === 0) {
            throw new Error('values not found');
        }

        // recupero i dati da values
        const { eventId = null, acceptedBy = null, operatorName = null, operatorLastName = null } = values;

        // controllo che in values sia presente eventId e che sia un numero valido
        if (!eventId || isNaN(eventId)) {
            throw new Error('eventId not found');
        }

        // controllo che acceptedBy sia presente e sia un numero valido
        if (!acceptedBy || isNaN(acceptedBy)) {
            throw new Error('acceptedBy not found');
        }

        // controllo che esista operatorName e operatorLastName e che siano stringhe
        if (!operatorName || typeof operatorName !== 'string' || !operatorLastName || typeof operatorLastName !== 'string') {
            throw new Error('operatorName or operatorLastName not found');
        }

        // recupero lo userId dell'utente
        const { userID = null } = userData?.value || {};

        // controllo che userID sia un numero valido
        if (!userID || isNaN(userID)) {
            throw new Error('userID not found');
        }

        // recupero l'evento dall'array delle props
        const event = props.notAssignedEvents.find(event => parseInt(event.eventData.eventID) === parseInt(eventId));

        // controllo che l'evento sia un oggetto valido
        if (!event || typeof event !== 'object' || Object.keys(event).length === 0) {
            throw new Error('event not found');
        }

        // controllo che eventData sia un oggetto valido
        if (!event?.eventData || typeof event?.eventData !== 'object' || Object.keys(event?.eventData).length === 0) {
            throw new Error('eventData not found');
        }

        // recupero l'eventId dall'evento
        const localEventId = event?.eventData?.eventID ? parseInt(event.eventData.eventID) : null;

        // controllo che l'eventId sia uguale all'id locale
        if (eventId !== localEventId) {
            return;
        }

        // setto operatorID solo se l'utente ha accettato l'evento
        parseInt(acceptedBy) === userID ? (event.eventData.operatorID = userID) : (event.eventData.operatorID = null);

        // setto operatorName e operatorLastName 
        event.eventData.operatorName = operatorName;
        event.eventData.operatorLastName = operatorLastName;
        event.active = true;

        // invio l'accettazione
        parseInt(acceptedBy) === userID && (
            SocketStore.emit('chatMessage', { 
                messageText: `${operatorName} ha accettato l'evento n° ${eventId}`,
                messageType: 'eventAck'
            })
        )

        // emetto l'update degli eventi attivi
        emits('acceptEvent', event);

    });

    onMounted(() => {
        SocketStore.on('eventAck', async (values) => await eventRequestAccept(values));
    });

    onUnmounted(() => {
        SocketStore.off('eventAck');
    });

</script>

<style scoped>
    .event-list-move,
    .event-list-enter-active,
    .event-list-leave-active {
        transition: all 0.5s ease;
    }

    .event-list-enter-from,
    .event-list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .event-list-leave-active {
        position: absolute;
    }
</style>