<template>
    <section class="h-full max-h-[calc(100vh-_6rem)] bg-surface-50 dark:bg-surface-800 rounded-lg overflow-y-auto overflow-x-hidden">
        <DataView :value="eventsList" :key="dataViewKey" :paginator="true" :rows="9" :layout="'grid'"  class="w-full !min-h-full">
                
            <template #header>
                <div class="flex flex-wrap gap-4 items-center justify-between">
                        
                    <!-- Icona e testo -->
                    <div class="flex items-center justify-start gap-2">
                        <span class="material-symbols-outlined material-symbols-font-300">event_available</span>
                        <h2 class="font-bold text-base tracking-tight uppercase">Eventi</h2>
                    </div>

                    <!-- Ricerca e filtro -->
                    <div class="flex flex-wrap items-end gap-4">

                        <!-- Filtro per attivo o terminato -->
                        <SelectButton v-model="eventType" :options="['active', 'terminated', 'suspended']" :allowEmpty="false">
                            <template #option="{ option }">
                                {{ $t(`events.eventTypes.${option}`) }}
                            </template>
                        </SelectButton>

                        <!-- Ricerca -->
                        <IconField>
                            <InputIcon class="pi pi-search" />
                            <InputText placeholder="Cerca..." v-model="searchQuery" />
                        </IconField>

                    </div>

                </div>
            </template>
                
            <template #grid="slotProps">
                <div class="grid grid-cols-1 md:grid-cols-auto-fill-400 gap-4 py-3 px-2">
                    <div v-for="item in slotProps.items" :key="item.eventData.eventID" class="col-span-1">
                        <BlockUI :blocked="item?.eventData?.operatorID !== userData?.userID && !item?.suspended">
                            <Card
                                @click="showEventDetails(item)" 
                                class="h-full overflow-y-auto cursor-pointer !border-2 !border-surface-100 dark:!border-surface-700 transition-all duration-300"
                                :class="{ 'dark:!bg-surface-600 !bg-surface-200': item?.eventData?.eventID === props?.selectedEventId }"
                            >

                                <!-- Header -->
                                <template #header>
                                    <div class="flex justify-between items-center p-3 rounded-t-lg text-sm bg-surface-100 dark:bg-surface-700">

                                        <!-- Parte sinistra -->
                                        <div class="flex items-center gap-3">
                                            
                                            <!-- Indicazione di attivazione -->
                                            <span class="relative left-1 flex h-4 w-4 cursor-pointer" v-tooltip.top="item?.active ? 'Evento in corso' : item?.suspended ? 'Evento sospeso' : 'Evento terminato'" >
                                                <span class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75" :class="{ 'bg-red-500 dark:bg-red-300': !item?.active && !item?.suspended, 'bg-yellow-500 dark:bg-yellow-300': item?.suspended, 'bg-green-500 dark:bg-green-300': item?.active }"></span>
                                                <span class="relative inline-flex rounded-full h-4 w-4" :class="{ 'bg-red-600 dark:bg-red-400': !item?.active && !item?.suspended, 'bg-yellow-600 dark:bg-yellow-400': item?.suspended, 'bg-green-600 dark:bg-green-400': item?.active }"></span>
                                            </span>

                                            <!-- Evento -->
                                            <span class="font-semibold uppercase tracking-wide" v-if="item?.eventData?.eventID != null"> Evento n° {{ item.eventData.eventID }} </span>

                                        </div>

                                        <!-- Badge operatore -->
                                        <div v-if="item?.eventData?.operatorName && item?.eventData?.operatorLastName" class="flex items-center gap-2 font-semibold">
                                            <span>Operatore:</span>
                                            <span class="text-base max-w-24 lg:max-w-full truncate">{{ item?.eventData?.operatorName }} {{ item?.eventData?.operatorLastName }}</span>
                                        </div>

                                    </div>
                                </template>

                                <!-- Contenuto -->
                                <template #content>
                                    <div class="space-y-2 transition-colors duration-300">

                                        <!-- Imei dispositivo -->
                                        <div class="flex gap-2 items-center justify-start" v-if="item?.eventData?.imei && typeof item?.eventData?.imei === 'string'">
                                            <div class="flex gap-1 items-center">
                                                <span class="material-symbols-outlined material-symbols-font-300">directions_car</span>
                                                <span class="font-semibold">{{ $t(`events.eventsData.imei`) }}:</span>
                                            </div>
                                            <span class="max-w-24 lg:max-w-full truncate">{{ item?.eventData?.imei }}</span>
                                        </div>

                                        <!-- dt_tracker -->
                                        <div class="flex gap-2 items-center justify-start" v-if="item?.eventData?.dt_tracker && typeof item?.eventData?.dt_tracker === 'string'">
                                            <div class="flex gap-1 items-center">
                                                <span class="material-symbols-outlined material-symbols-font-300">calendar_month</span>
                                                <span class="font-semibold">{{ $t(`events.eventsData.dt_tracker`) }}:</span>
                                            </div>
                                            <span class="max-w-24 lg:max-w-full truncate">{{ $d(item?.eventData?.dt_tracker, 'messageDate') }}</span>
                                        </div>

                                        <!-- Primo cliente del dispositivo -->
                                        <div class="flex gap-2 items-center justify-start" v-if="item?.gsUserData && typeof item?.gsUserData === 'object' && Object.keys(item?.gsUserData).length > 0 && item?.gsUserData[0] && typeof item?.gsUserData[0] === 'object' && Object.keys(item?.gsUserData[0]).length > 0">
                                            <div class="flex gap-1 items-center">
                                                <span class="material-symbols-outlined material-symbols-font-300">person_pin_circle</span>
                                                <span class="font-semibold">Cliente principale:</span>
                                            </div>
                                            <span class="max-w-24 lg:max-w-full truncate">{{ item?.gsUserData[0]?.company }}</span>
                                        </div>

                                        <!-- Indirizzo dispositivo -->
                                        <div class="flex gap-2 items-center justify-start" v-if="item?.eventData?.address && typeof item?.eventData?.address === 'string'">
                                            <div class="flex gap-1 items-center">
                                                <span class="material-symbols-outlined material-symbols-font-300">location_on</span>
                                                <span class="font-semibold">{{ $t(`events.eventsData.address`) }}:</span>
                                            </div>
                                            <span class="max-w-24 lg:max-w-full truncate">{{ item?.eventData?.address }}</span>
                                        </div>

                                        <!-- Velocità -->
                                        <div class="flex gap-2 items-center justify-start" v-if="item?.eventData?.speed && typeof item?.eventData?.speed === 'number'">
                                            <div class="flex gap-1 items-center">
                                                <span class="material-symbols-outlined material-symbols-font-300">speed</span>
                                                <span class="font-semibold">{{ $t(`events.eventsData.speed`) }}:</span>
                                            </div>
                                            <span class="max-w-24 lg:max-w-full truncate">{{ formatterSpeed(item?.eventData?.speed) }}</span>
                                        </div>

                                        <!-- Motivazione chiusura -->
                                        <div class="flex gap-2 items-center justify-start" v-if="typeof item?.eventData?.motivationText === 'string'">
                                            <div class="flex gap-1 items-center">
                                                <span class="material-symbols-outlined material-symbols-font-300">warning</span>
                                                <span class="font-semibold">
                                                    {{ item?.suspended ? 'Motivo sospensione:' : 'Motivo chiusura:' }}
                                                </span>
                                            </div>
                                            <span class="max-w-24 lg:max-w-full truncate">{{ item?.eventData?.motivationText }}</span>
                                        </div>

                                    </div>
                                </template>

                            </Card>
                        </BlockUI>
                    </div>
                </div>
            </template>
        
        </DataView>
    </section>
    
</template>

<script setup>

    // Base imports
    import { ref, onMounted, nextTick, computed, onUnmounted } from 'vue';
  
    // Store imports
    import { useAuthStore } from '@/stores/auth.js';
    import { useSocketStore } from '@/stores/socket';

    // Services imports
    import { handleErrorsSilent } from '@/helpers/errorHandler';
    import { formatterSpeed } from '@/helpers/utils';
    import { audioService } from '@/services/AudioService';

    // Definisco le props
    const props = defineProps({
        activeEvents: {
            type: Array,
            default: null
        },
        terminatedEvents: {
            type: Array,
            default: null
        },
        suspendedEvents: {
            type: Array,
            default: null
        },
        selectedEventId: {
            type: Number,
            default: null
        }
    });

    // Recupero SocketStore e il bus del dialog
    const SocketStore = useSocketStore();

    // definisco gli emits
    const emits = defineEmits(['onViewDetails', 'onCloseDetails', 'onReloadEvents', 'onNewEvent']);

    // recupero i dati dell'utente
    const userData = computed(() => useAuthStore().getUser);

    // Definisco il modello degli eventi
    const activeEventsList = ref(props.activeEvents);
    const terminatedEventsList = ref(props.terminatedEvents);
    const suspendedEventsList = ref(props.suspendedEvents);

    // Definisco il modello del filtro di ricerca, della tipologia e la chiave del Data View
    const searchQuery = ref(null);
    const eventType = ref('active');
    const dataViewKey = ref(0);

    // computed per l'elenco totale degli eventi con chiave active, ricerca e filtro per tipo
    const eventsList = computed(() => {
        
        // Aggiungo le chiavi active agli eventi
        const activeEvents = activeEventsList.value.map(event => ({...event, active: true, suspended: false }));
        const terminatedEvents = terminatedEventsList.value.map(event => ({...event, active: false, suspended: false }));
        const suspendedEvents = suspendedEventsList.value.map(event => ({...event, active: false, suspended: true }));

        // Unisco i due elenchi
        let allEvents = [...activeEvents, ...terminatedEvents, ...suspendedEvents];
        
        // Filtro per tipo (attivi, terminati o tutti)
        if(eventType.value && typeof eventType.value === 'string') {
            switch (eventType.value.toLowerCase()) {
                case 'active':
                    allEvents = allEvents.filter(event => event.active);
                break;

                case 'terminated':
                    allEvents = allEvents.filter(event => !event.active && !event.suspended);
                break;

                case 'suspended':
                    allEvents = allEvents.filter(event => event.suspended);
                break;
            
                default:  break;
            }
        }

        // Filtra gli eventi in base alla ricerca, cercando in eventData
        if (searchQuery.value && typeof searchQuery.value === 'string') {
            const searchLower = searchQuery.value.toLowerCase();
            allEvents = allEvents.filter(event =>
                (event.eventData?.imei && event.eventData.imei.toLowerCase().includes(searchLower)) ||
                (event.eventData?.event && event.eventData.event.toLowerCase().includes(searchLower)) ||
                (event.eventData?.operatorName && event.eventData.operatorName.toLowerCase().includes(searchLower)) ||
                (event.eventData?.operatorLastName && event.eventData.operatorLastName.toLowerCase().includes(searchLower)) ||
                (event.eventData?.company && event.eventData.company.toLowerCase().includes(searchLower)) ||
                (event.eventData?.address && event.eventData.address.toLowerCase().includes(searchLower)) || 
                (event.eventData?.dt_tracker && event.eventData.dt_tracker.toLowerCase().includes(searchLower)) ||
                (event.eventData?.eventID && event.eventData.eventID.toString().includes(searchLower))
            );
        }

        // Ordina gli eventi in modo che quelli attivi vengano prima
        allEvents.sort((a, b) => {

            // recupero il check se l'evento è mio
            const isMyEventA = a.eventData?.operatorID === userData?.value?.userID;
            const isMyEventB = b.eventData?.operatorID === userData?.value?.userID;

            // Prima ordina per attivo
            if (b.active !== a.active) {
                return b.active - a.active;
            }

            // Poi ordina per chi è il proprietario
            if(eventType.value === 'active'){
                if (isMyEventB !== isMyEventA) {
                    return isMyEventB ? 1 : -1;
                }
            }

            // Se eventType è 'active', ordino in ordine crescente per eventID
            if (eventType.value === 'active') {
                return a.eventData?.eventID - b.eventData?.eventID;
            }

            // Se eventType non è 'active', ordina in ordine decrescente per eventID
            return b.eventData?.eventID - a.eventData?.eventID;
        });

        return allEvents;
    });

    // Definisco la funzione per visualizzare un nuovo evento
    const showNewEvent = handleErrorsSilent(async (message) => {
        
        // Aspetto che i componenti vengano renderizzati
        await nextTick();

        // Emetto il nuovo evento
        emits('onNewEvent', message);

        // Play audio
        audioService.play();
    });

    // Definisco la funzione per visualizzare i dettagli dell'eventoù
    const selectedEventId = ref(null);
    const showEventDetails = handleErrorsSilent(async (event) => {

        if(!event || typeof event !== 'object' || Object.keys(event).length === 0) {
            throw new Error('event not found');
        }

        // setto una condizione per vedere se l'evento è già selezionato
        let condition = selectedEventId?.value != null ? parseInt(selectedEventId?.value) === parseInt(event.eventData.eventID) : false;
        
        // effettuo l'emit in base alla condizione
        condition ? emits('onCloseDetails') : emits('onViewDetails', event);

        // setto il valore di selectedEventId
        selectedEventId.value = condition ? null : event.eventData.eventID;
    });

    // Definisco la funzione per ricaricare gli eventi graficamente
    const reloadEvents = handleErrorsSilent(async (eventTypeReload = null) => {
        dataViewKey.value++;
        searchQuery.value = null;
        eventTypeReload !== null && (eventType.value = eventTypeReload);
    });

    // Definisco i metodi utili da ascoltare dal socket
    onMounted(() => {
        SocketStore.on('event', (message) => showNewEvent(message)); // nuovo evento
    });

    onUnmounted(() => {
        SocketStore.off('event');
    });

    // esporto i metodi utili
    defineExpose({ showNewEvent, reloadEvents });

</script>
