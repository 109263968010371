export default {
    op_operatorID: 'ID',
    op_name: 'Nome',
    op_lastName: 'Cognome',
    op_userName: 'Nome utente',
    op_email: 'Email',
    op_cell: 'Cellulare',
    op_created_at: 'Creato il',
    op_updated_at: 'Aggiornato il',
    op_password: 'Password',
    op_userType: 'Tipo utente',
}