<template>

  <Skeleton v-if="!data" class="h-full"></Skeleton>

  <div v-else class="grid grid-cols-1 lg:grid-cols-12 gap-6 h-full" :key="keyTest">

    <div class="lg:col-span-3 col-span-full flex flex-col h-full max-h-[calc(100vh-_6rem)] gap-8">
      
      <div class="flex-grow overflow-auto overflow-x-hidden bg-surface-50 dark:bg-surface-900 rounded-lg space-y-4 p-2">
        <NotAssignedEvents :notAssignedEvents="notAssignedEvents" @acceptEvent="acceptEventHandler" />
      </div>

      <Chat :messages="data?.lastMessages" class="flex-shrink-0 h-[26rem] bg-surface-50 dark:bg-surface-800"/>
    
    </div>

    <!-- Events and details -->
    <div class="lg:col-span-9 col-span-full rounded-lg grid grid-cols-1 lg:grid-cols-12 gap-6 transition-all duration-300">

      <!-- Events -->
      <div class="lg:col-span-7" :class="{ 'lg:col-span-full': !eventDetailsShow, 'lg:col-span-7': eventDetailsShow }">
        <Events
          ref="eventsRef"
          :activeEvents="data?.activeEvents"
          :terminatedEvents="data?.terminatedEvents"
          :suspendedEvents="data?.suspendedEvents"
          :selectedEventId="selectedEvent?.eventData?.eventID || null"
          @onViewDetails="showEventDetails"
          @onCloseDetails="hideEventDetails"
          @onReloadEvents="updateActiveEvents"
          @onNewEvent="updateNotAssignedEvents"
        />
      </div>

      <!-- Event Details -->
      <div class="lg:col-span-5" v-if="eventDetailsShow && selectedEvent">
        <EventDetail 
          :event="selectedEvent"
          :motivationTypes="data?.motivationTypes"
          @hide="hideEventDetails"
        />
      </div>

    </div>

  </div>

  <!-- Dialog per il permesso audio -->
  <Dialog v-model:visible="showAudioPermissionDialog" :modal="true" :closable="false" header="Permesso Audio" class="!z-all">
    <p>Per una migliore esperienza, questa applicazione utilizza notifiche audio.</p>
    <template #footer>
      <Button label="Ok" @click="hideAudioPermissionDialog" />
    </template>
  </Dialog>
 
</template>

<script setup>

  // base imports
  import { ref, onMounted, nextTick, computed, onUnmounted } from 'vue';

  // store imports
  import { useAuthStore } from '@/stores/auth.js';
  import { useSocketStore } from '@/stores/socket';

  // services imports
  import { handleErrorsSilent } from '@/helpers/errorHandler';
  import { audioService } from '@/services/AudioService';

  // import dei components
  import Chat from '@/views/baseView/components/Chat.vue';
  import Events from '@/views/baseView/components/Events.vue';
  import EventDetail from '@/views/baseView/components/EventDetail.vue';
  import NotAssignedEvents from '@/views/baseView/components/NotAssignedEvents.vue';

  // recupero SocketStore
  const SocketStore = useSocketStore();

  // Definisco il modello dei dati 
  const data = ref(null);

  // Definisco i refs per la sezione eventi
  const eventsRef = ref(null);

  // definisco i riferimenti per la sezione dettagli evento
  const eventDetailsShow = ref(false);
  const selectedEvent = ref(null);

  // definisco il ref per il dialog del permesso audio
  const showAudioPermissionDialog = ref(true);

  // recupero i dati dell'utente
  const userData = computed(() => useAuthStore().getUser);
  const keyTest = ref(0);

  // faccio un computed per l'elenco dei notAssignedEvents per ordinarli in base a eventData.eventID decrescente
  const notAssignedEvents = computed(() => {
    return data.value?.notAssignedEvents?.sort((a, b) => b.eventData?.eventID - a.eventData?.eventID);
  });
  
  // definisco la funzione per mostrare i dettagli dell'evento
  const showEventDetails = handleErrorsSilent(async (event) => {

    // aspetto il caricamento dei componenti 
    await nextTick();

    // controllo che event esista e sia un oggetto valido
    if (!event || typeof event !== 'object' || Object.keys(event).length === 0) {
      throw new Error('Event not defined');
    }

    // imposto l'evento selezionato
    selectedEvent.value = event;

    // imposto la visibilità del dialog
    eventDetailsShow.value = true;

  });

  // definisco la funzione per nascondere i dettagli dell'evento
  const hideEventDetails = handleErrorsSilent(async () => {

    // aspetto il caricamento dei componenti 
    await nextTick();

    // imposto la visibilità del dialog
    eventDetailsShow.value = false;
    selectedEvent.value = null;
    
  });

  // definisco la funzione per gestire bene l'aggiornamento degli eventi
  const updateActiveEvents = handleErrorsSilent(async (event = null) => {

    // aspetto il caricamento dei componenti 
    await nextTick();

    // controllo che event esista e sia un oggetto 
    if (!event || typeof event !== 'object' || Object.keys(event).length === 0) {
      throw new Error('Event not defined');
    }

    // recupero i valori da event
    const { values = null, mode = 'add', reference = 'activeEvents', eventTypeReload = null } = event;

    // controllo che values esista e sia un oggetto 
    if (!values || typeof values !== 'object' || Object.keys(values).length === 0) {
      throw new Error('Values not defined');
    }

    // controllo che esista data
    if (!data.value || typeof data.value !== 'object' || Object.keys(data.value).length === 0) {
      throw new Error('Data not defined');
    }

    // controllo che esista eventsRef
    if (!eventsRef.value || typeof eventsRef.value?.reloadEvents !== 'function') {
      throw new Error('EventsRef not defined');
    } 

    // controllo che esista activeEventsList 
    if (!data?.value?.activeEvents) {
      throw new Error('activeEvents not defined');
    }

    switch(mode){
      case 'add':
        data.value[reference].push(values);
      break;
      
      case 'remove':
        const index = data.value[reference].findIndex(event => event.eventData?.eventID === values.eventData?.eventID);
        if (index !== -1) {
          data.value[reference].splice(index, 1);
        }
      break;

    }

    // aggiorno gli eventi
    eventsRef.value?.reloadEvents(eventTypeReload);

  });

  // definisco la funzione per aggiornare gli eventi non assegnati
  const updateNotAssignedEvents = handleErrorsSilent(async (event = null, mode = 'add') => {

    // aspetto il caricamento dei componenti 
    await nextTick();

    // controllo che event esista e sia un oggetto 
    if (!event || typeof event !== 'object' || Object.keys(event).length === 0) {
      throw new Error('Event not defined');
    }

    // controllo che esista data
    if (!data.value || typeof data.value !== 'object' || Object.keys(data.value).length === 0) {
      throw new Error('Data not defined');
    }
    
    // aggiungo o rimuovo l'evento
    switch(mode){
      case 'add':
        data.value.notAssignedEvents = [...data.value.notAssignedEvents, event];
      break;
      case 'remove':
        const index = data.value.notAssignedEvents.findIndex(event => event.eventData?.eventID === event.eventData?.eventID);
        if (index !== -1) {
          data.value.notAssignedEvents.splice(index, 1);
        }
      break;
    }
  });

  // definisco la funzione per nascondere il dialog del permesso audio
  const hideAudioPermissionDialog = handleErrorsSilent(async () => {
    
    // aspetto il caricamento dei componenti 
    await nextTick();

    // controllo che esista data.value.notAssignedEvents
    if (data.value?.notAssignedEvents && data.value?.notAssignedEvents?.length > 0) {
      audioService.play();
    }

    // nascondo il dialog
    showAudioPermissionDialog.value = false;
  });

  // definisco la funzione per gestire l'accettazione di un evento
  const acceptEventHandler = handleErrorsSilent(async (event) => {

    // controllo che event esista e sia un oggetto 
    if (!event || typeof event !== 'object' || Object.keys(event).length === 0) {
      throw new Error('Event not defined');
    }

    // controllo che esista event?.data
    if (!event?.eventData || typeof event?.eventData !== 'object' || Object.keys(event?.eventData).length === 0) {
      throw new Error('Event data not defined');
    }

    // recupero lo userId dell'utente
    const { userID = null } = userData?.value || {};

    // controllo che userID sia un numero valido
    if (!userID || isNaN(userID)) {
      throw new Error('userID not found');
    }

    // aggiorno gli eventi
    updateActiveEvents({ values: event, mode: 'add', eventTypeReload: 'active' });
    updateNotAssignedEvents(event, 'remove');

    // mostro le informazioni solo se operatoreID è uguale all'id dell'operatore loggato
    event?.eventData?.operatorID && parseInt(event?.eventData?.operatorID) === parseInt(userID) && selectedEvent.value === null && showEventDetails(event)

  });

  // definisco la funzione per eseguire la logica dei cambi di stato
  const executeEvent = handleErrorsSilent(async (values, mode = 'terminated') => {

    // definisco la funzione base da richiamare per eseguire la logica dei cambio stati
    const checkExecuteEvent = handleErrorsSilent(async (values, resume = false) => {
        
      // aspetto il tick successivo
      await nextTick();
  
      // controllo che values sia un oggetto valido
      if (!values || typeof values !== 'object' || Object.keys(values).length === 0) {
        throw new Error('values not found');
      }
  
      // recupero i dati da values
      const { eventID = null, terminatedBy = null, acceptedBy = null, motivationID = null, motivationText = null, operatorName = null, operatorLastName = null } = values;
  
      // controllo che in values sia presente eventId e che sia un numero valido
      if (!eventID || isNaN(eventID)) {
        throw new Error('eventID not found');
      }
  
      // controllo che acceptedBy sia presente e sia un numero valido solo se resume è true
      if (resume && (!acceptedBy || isNaN(acceptedBy))) {
        throw new Error('acceptedBy not found');
      }
  
      // controllo che acceptedBy sia presente e sia un numero valido solo se resume è false
      if (!resume && (!terminatedBy || isNaN(terminatedBy))) {
        throw new Error('acceptedBy not found');
      }
  
      // controllo che motivationID sia presente e sia un numero valido solo se resume è false
      if (!resume && (!motivationID || isNaN(motivationID))) {
        throw new Error('motivationID not found');
      }
  
      // controllo che esista data?.value?.motivationTypes
      if (!data?.value?.motivationTypes || !Array.isArray(data?.value?.motivationTypes) || data?.value?.motivationTypes?.length === 0) {
        throw new Error('motivationTypes not found');
      }
  
      // controllo che motivationId sia presente in props?.motivationTypes solo se resume è false
      if (!resume && (!data?.value?.motivationTypes?.some(type => parseInt(type?.motivationID) === parseInt(motivationID)))) {
        throw new Error('motivationID not found in props.motivationTypes');
      }
  
      // recupero lo userId dell'utente
      const { userID = null } = userData?.value || {};
  
      // controllo che userID sia un numero valido
      if (!userID || isNaN(userID) || !operatorName || !operatorLastName) {
        throw new Error('userID not found');
      }
  
      return {
        eventID,
        userIdRequest: resume ? acceptedBy : terminatedBy,
        ...(!resume && { motivationID, motivationText }),
        userID,
        operatorName,
        operatorLastName
      }
    
    });

    // definisco la funzione per trovare l'evento in base all'eventID
    const findEventById = (eventID, mode = 'terminated') => {

      // in base alla mode definisco il riferimento per l'aggiunta
      let addReference = mode === 'resume' ? 'activeEvents' : mode === 'terminated' ? 'terminatedEvents' : 'suspendedEvents';
      let eventTypeReload = mode === 'resume' ? 'active' : mode === 'terminated' ? 'terminated' : 'suspended';
      

      const activeEvent = data.value?.activeEvents?.find(event => event.eventData?.eventID === eventID);
      if (activeEvent) {
        return { removeReference: 'activeEvents', addReference, eventTypeReload, event: activeEvent };
      }

      const suspendedEvent = data.value?.suspendedEvents?.find(event => event.eventData?.eventID === eventID);
      if (suspendedEvent) {
        return { removeReference: 'suspendedEvents', addReference, eventTypeReload, event: suspendedEvent };
      }

      const terminatedEvent = data.value?.terminatedEvents?.find(event => event.eventData?.eventID === eventID);
      if (terminatedEvent) {
        return { removeReference: 'terminatedEvents', addReference, eventTypeReload, event: terminatedEvent };
      }

      return null;
    };
    
    // recupero i dati da values
    const {
      eventID,
      userIdRequest,
      motivationID = null,
      motivationText = null,
      userID,
      operatorName,
      operatorLastName
    } = await checkExecuteEvent(values, mode === 'resume');

    // recupero i dati dell'evento
    const { removeReference = null, addReference = null, eventTypeReload = null, event = null } = findEventById(eventID, mode);

    // controllo che removeReference e addReference siano presenti e siano stringhe
    if (!removeReference || !addReference || typeof removeReference !== 'string' || typeof addReference !== 'string') {
      throw new Error('removeReference or addReference not found');
    }

    // controllo che eventData sia un oggetto valido
    if (!event || typeof event !== 'object' || Object.keys(event).length === 0) {
      throw new Error('event not found');
    }

    // creo una copia locale dell'evento
    let eventCopy = { ...event };

    // setto nuove proprietà in eventCopy.eventData
    eventCopy.eventData = {
      ...eventCopy.eventData,
      ...(mode != 'resume' && {
        motivationID: motivationID || null,
        motivationText: motivationText || (data?.value?.motivationTypes?.find(type => parseInt(type?.motivationID) === parseInt(motivationID))?.description || null)
      }),
      ...(mode === 'resume' && {
        motivationID: null,
        motivationText: null,
        operatorID: userIdRequest,
        operatorName,
        operatorLastName
      })
    };

    // rimuovo e aggiungo dinamicamente
    updateActiveEvents({
      values: eventCopy,
      mode: 'remove',
      reference: removeReference,
      ...(parseInt(userIdRequest) === userID && { eventTypeReload })
    });

    updateActiveEvents({
      values: eventCopy,
      mode: 'add',
      reference: addReference,
      ...(parseInt(userIdRequest) === userID && { eventTypeReload })
    });

    // invio il messaggio di chiusura e nascono l'evento selezionato
    if(parseInt(userIdRequest) === userID){
      const actionText = mode === 'resume' ? 'ripreso' : mode === 'terminated' ? 'chiuso' : 'sospeso';
      SocketStore.emit('chatMessage', { messageText: `${operatorName} ${operatorLastName} ha ${actionText} correttamente l'evento n° ${eventID}`, messageType: 'eventAck' });
      hideEventDetails();
    }

  });

  onMounted(async () => {
    
    // richiedo al socket i dati
    SocketStore.emit('requestData');

    // visualizzo i dati del socket
    SocketStore.on('loadData', (values) => data.value = values);

    // definisco gli ack per le operazioni sull'evento
    SocketStore.on('eventTerminatedAck', (message) => executeEvent(message, 'terminated')); // chiusura evento
    SocketStore.on('eventSuspendedAck', (message) => executeEvent(message,'suspended')); // sospensione evento
    SocketStore.on('eventResumeAck', (message) => executeEvent(message,'resume')); // ripresa dell'evento

  });

  // definisco il metodo per rimuovere i listener del socket
  onUnmounted(() => {
    SocketStore.off('eventTerminatedAck');
    SocketStore.off('eventSuspendedAck');
    SocketStore.off('eventResumeAck');
    SocketStore.off('loadData');
  });

</script>

<style>
  .p-blockui-mask {
    z-index: 1100 !important;
  }
</style>