<template>
    
    <!-- Dialog per la conferma -->
    <Dialog 
        v-model:visible="dialogVisible"
        modal
        :header="'Conferma ' + (formModel?.mode?.model === 'close' ? 'chiusura' : 'sospensione') + ' evento'"
        position="topleft"
        :style="{ width: '25rem' }"
    >

        <!-- Intestazione -->
        <span class="text-surface-500 dark:text-surface-400 block mb-8">
            Sei sicuro di voler
            {{ formModel?.mode?.model === 'close' ? 'chiedere la chiusura' : 'sospendere' }}
            dell'evento {{ formModel?.eventID?.model ? `n°${ formModel?.eventID?.model }` : 'selezionato' }} ?
        </span>

        <!-- Form  @submit="sendCloseRequest"-->
        <FormGenerator
            ref="formRef"
            :key="formKey"
            class="w-full"
            :tKey="'events'"
            :fields="formModel"
            @submit="sendRequest"
        />

    </Dialog>

    <div class="flex-1 flex items-center justify-start gap-4 px-8" v-if="typeof event === 'object' && event !== null">

        <!-- Pulsante per terminare l'evento -->
        <template v-if="event?.active || event?.suspended">
            <Button 
                :size="'small'" 
                icon="pi pi-stop-circle" 
                fluid 
                label="Termina" 
                severity="warn"  
                @click="showModal(event?.eventData?.eventID)" 
            />
        </template>

        <!-- Pulsante per sospendere un evento -->
        <template v-if="event?.active || (!event?.suspended && !event?.active)">
            <Button
                :size="'small'"
                icon="pi pi-pause"
                fluid
                label="Sospendi"
                severity="help"
                @click="showModal(event?.eventData?.eventID, 'suspend')" 
            />
        </template>

        <!-- Pulsante per riprendere un evento sospeso -->
        <template v-if="event?.suspended">
            <Button
                :size="'small'"
                icon="pi pi-active"
                fluid
                label="Riprendi evento"
                @click="sendResumeEvent(event?.eventData?.eventID)"
            />
        </template>

    </div>
    
</template>

<script setup lang="js">

    // base imports
    import { ref, computed } from 'vue';

    // store imports
    import { useAuthStore } from '@/stores/auth.js';
    import { useSocketStore } from '@/stores/socket';

    // services imports
    import { handleErrorsSilent } from '@/helpers/errorHandler';
    import { fillFormModel } from '@/helpers/form';
    
    // definisco le props
    const props = defineProps({
        event: {
            type: Object,
            required: true,
            validator: (value) => {
                return typeof value === 'object' && value !== null;
            }
        },
        motivationTypes: {
            type: Array,
            default: null
        },
    });

    // recupero SocketStore
    const SocketStore = useSocketStore();

    // recupero i dati dell'utente
    const userData = computed(() => useAuthStore().getUser);

    // definisco il modello e il riferimento del dialog di conferma di chiusura dell'evento
    const dialogVisible = ref(false);
    const formRef = ref(null);
    const formKey = ref(0);
    const formModel = ref({
        eventID: {
            key: 'eventID',
            model: null,
            type: 'text',
            rules: 'required',
            notShow: true
        },
        imei: {
            key: 'imei',
            model: null,
            type: 'text',
            rules: 'required',
            notShow: true  
        },
        motivationID: {
            key: 'motivationID',
            model: null,
            type: 'dropdown',
            bind: {
                options: props.motivationTypes || [],
                optionValue: 'motivationID',
                optionLabel: 'description',
                onchange: (event) => {
                    if(parseInt(event?.value) === 6){
                        formRef.value?.onUpdateField('motivationText', false, ['notShow']);
                    } else {
                        formRef.value?.onUpdateField('motivationText', true, ['notShow']);
                        formRef.value?.onUpdateField('motivationText', null, ['model']);
                    }
                }
            },
            rules: 'required'
        },
        motivationText: {
            key: 'motivationText',
            model: null,
            type: 'text',
            rules: 'required',
            notShow: true
        },
        mode: {
            key: 'mode',
            model: null,
            type: 'text',
            model: 'close', // close || suspend
            notShow: true
        }
    }); 

    // definisco la funzione per mostrare il dialog
    const showModal = handleErrorsSilent(async (eventID = null, mode = 'close') => {

        // controllo che esista eventId
        if (!eventID || isNaN(eventID)) {
            throw new Error('eventId not found');
        }

        // controllo che esista props?.event e props?.event?.eventData
        if (!props?.event || !props?.event?.eventData) {
            throw new Error('props.event o props.event.eventData non esiste');
        }

        // controllo che eventID sia uguale a props.event.eventData.eventID
        if (parseInt(eventID) !== parseInt(props?.event?.eventData?.eventID)) {
            throw new Error('eventID non corrisponde a props.event.eventData.eventID');
        }

        // recupero l'imei dall'evento
        const { imei = null } = props?.event?.eventData || {};

        // controllo che imei esista e sia una stringa
        if (!imei || typeof imei !== 'string') {
            throw new Error('imei non esiste o non è una stringa');
        }

        // controllo che mode sia una stringa e che sia close o suspend
        if (typeof mode !== 'string' || (mode !== 'close' && mode !== 'suspend')) {
            throw new Error('mode non è una stringa o non è close o suspend');
        }

        // riempio il modello del form
        formModel.value = fillFormModel(formModel, { eventID, imei, mode });

        // ricarico la chiave del form
        formKey.value++;

        // visualizzo il dialog di chiusura
        dialogVisible.value = true;

    });

    // definisco la funzione per inviare la richiesta
    const sendRequest = handleErrorsSilent(async (values) => {

        // recupero i dati
        const { event: data } = values;

        // controllo che esista mode in data e che sia close o suspend
        if (!data?.mode || (data?.mode !== 'close' && data?.mode !== 'suspend')) {
            throw new Error('mode non esiste o non è close o suspend');
        }

        // invio la richiesta al socket
        switch (data?.mode) {
            case 'close':
                SocketStore.emit('eventTerminated', data);
            break;
            case 'suspend':
                SocketStore.emit('eventSuspended', data);
            break;
        }

        // chiudo il dialog
        dialogVisible.value = false;

    });

    // definisco la funzione per mandare la richiesta di ripresa evento
    const sendResumeEvent = handleErrorsSilent(async (eventID = null) => {

        // controllo che eventId sia un numero valido
        if (!eventID || isNaN(eventID)) {
            throw new Error('eventId not found');
        }

        // controllo che esista props?.event e props?.event?.eventData
        if (!props?.event || !props?.event?.eventData) {
            throw new Error('props.event o props.event.eventData non esiste');
        }

        // controllo che eventID sia uguale a props.event.eventData.eventID
        if (parseInt(eventID) !== parseInt(props?.event?.eventData?.eventID)) {
            throw new Error('eventID non corrisponde a props.event.eventData.eventID');
        }

        const { eventData = null } = props?.event;

        // controllo che esista event.eventData
        if (!eventData || typeof eventData !== 'object' || Object.keys(eventData).length === 0) {
            throw new Error('eventData not found');
        }

        // controllo che eventData.imei sia presente e sia una stringa
        if (!eventData?.imei || typeof eventData?.imei !== 'string') {
            throw new Error('eventData.imei not found');
        }

        // recupero lo userId dell'utente
        const { userID = null, operatorName = null } = userData?.value || {};

        // controllo che userID sia un numero valido
        if (!userID || isNaN(userID) || !operatorName) {
            throw new Error('userID not found');
        }

        // invio la richiesta per riprendere l'evento
        SocketStore.emit('eventResume', { eventID, imei: eventData?.imei, operatorName });

    });

</script>