<template>

    <DefineTemplate v-slot="{ icon, label, noTranslate, value}">
        <div class="flex gap-2 items-center justify-start">
            <div class="flex gap-1 items-center">
                <span class="material-symbols-outlined material-symbols-font-300" v-if="icon">{{ icon }}</span>
                <span class="font-semibold">{{ !noTranslate ? $t(`events.eventsData.${label}`) : label }}:</span>    
            </div>
            <span class="max-w-24 lg:max-w-96 truncate" v-html="value"></span>
        </div>
    </DefineTemplate>

    <div class="h-full max-h-[calc(100vh-_6rem)] overflow-y-auto overflow-x-hidden rounded-lg bg-surface-200 dark:bg-surface-600">

        <!-- Intestazione e button -->
        <div class="flex flex-col gap-2 sticky top-0 shadow-sm shadow-surface-200 rounded-t-lg dark:shadow-surface-800 bg-surface-100 dark:bg-surface-700 z-50 pb-3">
          
            <!-- Intestazione -->
            <div class="relative flex items-center gap-2 w-full px-8 pt-4">
                <div class="flex items-center gap-2">
                    <span class="material-symbols-outlined material-symbols-font-300">info</span>
                    <p>Evento n° {{ event?.eventData?.eventID }}</p>
                </div>
                <Button 
                    text 
                    rounded 
                    aria-label="Close"
                    icon="pi pi-times" 
                    @click="emits('hide')" 
                    class="!absolute !top-0 !right-0"
                />
            </div>

            <div class="flex-1 flex items-center justify-start gap-4 px-8 pt-1">
                <Actions :event="event" :motivationTypes="motivationTypes" />
            </div>
        
        </div>

        <!-- Contenuto -->
        <div class="space-y-3 lg:space-y-6 py-3 transition-colors duration-300 text-base px-4">

            <!-- FieldSet della posizione -->
            <Fieldset :toggleable="true" v-if="event?.eventData?.lat && event?.eventData?.lng">

                <template #legend="{ toggleCallback }">
                    <div class="flex items-center pl-2 cursor-pointer" @click="toggleCallback">
                        <span class="material-symbols-outlined material-symbols-font-300"> map </span>
                        <span class="font-bold p-2">Dati del dispositivo satellitare</span>
                    </div>
                </template>

                <!-- Lat e lng -->
                <ReuseTemplate 
                    :icon="'location_on'" 
                    :label="'Lat e Lng'"
                    :noTranslate="true"
                    :value="`${event?.eventData?.lat} , ${event?.eventData?.lng}`" 
                    v-if="event?.eventData?.lat && event?.eventData?.lng"
                    class="py-2"
                />

                <!-- Targa dispositivo -->
                <ReuseTemplate 
                    :icon="'pin'" 
                    :label="'Targa del mezzo'" 
                    :value="getUserDataValue('plate_number')" 
                    v-if="getUserDataValue('plate_number')"
                    :noTranslate="true"
                    class="py-2"
                />

                <!-- Tipologia dispositivo -->
                <ReuseTemplate 
                    :icon="'star_half'" 
                    :label="'Tipologia dispositivo'" 
                    :value="getUserDataValue('vehicle_type_translated')" 
                    v-if="getUserDataValue('vehicle_type_translated')"
                    :noTranslate="true"
                    class="py-2"
                />

                <!-- Imei dispositivo -->
                <ReuseTemplate 
                    :icon="'directions_car'" 
                    :label="'imei'" 
                    :value="event?.eventData?.imei" 
                    v-if="event?.eventData?.imei && typeof event?.eventData?.imei === 'string'" 
                    class="py-2"
                />

                <!-- dt_tracker -->
                <ReuseTemplate 
                    :icon="'calendar_month'" 
                    :label="'dt_tracker'" 
                    :value="formatterDate(event?.eventData?.dt_tracker, 'messageDate')"
                    class="py-2"
                />

                <!-- Velocità -->
                <ReuseTemplate 
                    :icon="'speed'" 
                    :label="'speed'" 
                    :value="formatterSpeed(event?.eventData?.speed)" 
                    v-if="event?.eventData?.speed && typeof event?.eventData?.speed === 'number'"
                    class="py-2"
                />

                <!-- Indirizzo dispositivo -->
                <ReuseTemplate 
                    :icon="'location_on'" 
                    :label="'address'" 
                    :value="event?.eventData?.address" 
                    v-if="event?.eventData?.address && typeof event?.eventData?.address === 'string'"
                    class="py-2"
                />

                <!-- Motivazione terminazione-->
                <ReuseTemplate 
                    :icon="'warning'" 
                    :label="event?.suspended ? 'Motivo sospensione:' : 'Motivo chiusura:'"
                    :noTranslate="true"
                    :value="event?.eventData?.motivationText" 
                    v-if="event?.eventData?.motivationText && typeof event?.eventData?.motivationText === 'string'"
                    class="py-2"
                />

                <!-- Mappa -->
                <div class="flex items-center justify-center h-56 w-full" :class="{ 'animate-blink': isBlinking }">
                    <div ref="mapContainer" id="map" class="h-56 w-full rounded-lg"></div>
                </div>

            </Fieldset>

            <!-- FieldSet dei dati del primo cliente -->
            <Fieldset :toggleable="true" v-if="userDataExists">

                <template #legend="{ toggleCallback }">
                    <div class="flex items-center pl-2 cursor-pointer" @click="toggleCallback">
                        <span class="material-symbols-outlined material-symbols-font-300"> person_pin_circle </span>
                        <span class="font-bold p-2">Dati del cliente</span>
                    </div>
                </template>

                <div class="flex flex-col gap-2 items-start">

                    <!-- Nome -->
                    <ReuseTemplate 
                        :icon="'person_pin_circle'" 
                        :label="'Nome e cognome'"
                        :noTranslate="true"
                        :value="event?.gsUserData[0]?.company"
                        v-if="event?.gsUserData[0]?.company && typeof event?.gsUserData[0]?.company === 'string'"
                    />

                    <!-- contactNumber1 -->
                    <ReuseTemplate 
                        :icon="'phone'" 
                        :label="'Telefono'"
                        :noTranslate="true"
                        :value="getPhoneNumber(event?.gsUserData[0]?.contactNumber1)"
                        v-if="event?.gsUserData[0]?.contactNumber1 && typeof event?.gsUserData[0]?.contactNumber1 === 'string'"
                    />

                    <!-- contactNumber2 -->
                    <ReuseTemplate 
                        :icon="'phone'" 
                        :label="'Telefono'"
                        :noTranslate="true"
                        :value="getPhoneNumber(event?.gsUserData[0]?.contactNumber2)"
                        v-if="event?.gsUserData[0]?.contactNumber2 && typeof event?.gsUserData[0]?.contactNumber2 === 'string'"
                    />

                </div>

            </Fieldset>

            <!-- FieldSet dei referenti -->
            <Fieldset :toggleable="true" v-if="event?.users && typeof event?.users === 'object' && Object.keys(event?.users).length > 0">

                <template #legend="{ toggleCallback }">
                    <div class="flex items-center pl-2 cursor-pointer" @click="toggleCallback">
                        <span class="material-symbols-outlined material-symbols-font-300"> group </span>
                        <span class="font-bold p-2">Referenti</span>
                    </div>
                </template>

                <div class="space-y-2 divide-y divide-gray-300 divide-solid">
                    <div v-for="user in event?.users" :key="user?.userID || user" class="py-4">
                        <div v-if="user && typeof user === 'object' && Object.keys(user).length > 0" class="flex flex-col gap-2 items-start">

                            <!-- Nome e cognome -->
                            <ReuseTemplate 
                                :icon="'person_pin_circle'" 
                                :label="'Nome e cognome'"
                                :noTranslate="true"
                                :value="`${user?.name} ${user?.lastName}`"
                                v-if="user?.name && typeof user?.name === 'string' && user?.lastName && typeof user?.lastName === 'string'"
                            />

                            <!-- Primo numero di telefono -->
                            <ReuseTemplate 
                                :icon="'phone'" 
                                :label="'Telefono'"
                                :noTranslate="true"
                                :value="getPhoneNumber(user?.contactNumber1)"
                                v-if="user?.contactNumber1 && typeof user?.contactNumber1 === 'string'"
                            />

                            <!-- Email -->
                            <ReuseTemplate 
                                :icon="'email'" 
                                :label="'Email'"
                                :noTranslate="true"
                                :value="user?.email"
                                v-if="user?.email && typeof user?.email === 'string'"
                            />

                        </div>
                    </div>
                </div>

            </Fieldset>

        </div>

    </div>

</template>

<script setup lang="js">

    // base imports
    import { onMounted, onUnmounted, nextTick, ref, watch } from 'vue';

    // services imports
    import { handleErrorsSilent } from '@/helpers/errorHandler';
    import { formatterSpeed, formatterDate } from '@/helpers/utils';
    import { createReusableTemplate } from '@vueuse/core';

    // map services
    import MapService from '@/services/maps';

    // Store imports
    import { useSocketStore } from '@/stores/socket';

    // components imports
    import Actions from '@/views/baseView/components/Actions.vue';

    // Definisco i template riutilizzabili 
    const [ DefineTemplate, ReuseTemplate ] = createReusableTemplate();

    // definisco gli emits
    const emits = defineEmits(['hide']);

    // definisco le props
    const props = defineProps({
        event: {
            type: Object,
            required: true,
            validator: (value) => {
                return typeof value === 'object' && value !== null;
            }
        },
        motivationTypes: {
            type: Array,
            default: null
        },
    });

    // Recupero SocketStore e il bus del dialog
    const SocketStore = useSocketStore();

    // definisco il riferimento del container della mappa e del servizio
    const mapContainer = ref(null);
    let mapsService = null;
    const isBlinking = ref(false); // stato per far lampeggiare la sezione se si aggiorna la posizione

    // definisco il controllo per vedere se esiste props.event.gsUserData[0]
    const userDataExists = ref(props.event.gsUserData && typeof props.event.gsUserData === 'object' && Object.keys(props.event.gsUserData).length > 0 && props.event.gsUserData[0] && typeof props.event.gsUserData[0] === 'object' && Object.keys(props.event.gsUserData[0]).length > 0);

    // definisco la funzione per ritornare una chiave presente in props.event.gsUserData[0]
    const getUserDataValue = (key) => userDataExists.value ? props.event.gsUserData[0][key] : null;

    // definisco la funzione per controllare l'esistenza dei dati dell'evento e dei dati necessari per la mappa
    const checkExistencesEventData = handleErrorsSilent(async (checkMapContainer = true) => {

        // aspetto il tick successivo
        await nextTick();

        // controllo che esista props.event e che esista eventData
        if (!props.event || !props.event.eventData) {
            throw new Error('props.event o props.event.eventData non esiste');
        }

        // controllo che eventData abbia lat e lng
        if (!props.event.eventData.lat || !props.event.eventData.lng) {
            throw new Error('props.event.eventData.lat e props.event.eventData.lng devono esistere');
        }

        // verifico che lat e lng siano numeri validi 
        if (isNaN(props.event.eventData.lat) || isNaN(props.event.eventData.lng)) {
            throw new Error('props.event.eventData.lat e props.event.eventData.lng devono essere numeri validi');
        }

        // controllo che eventData abbia eventID e che sia un numero intero 
        if (!props.event.eventData.eventID || typeof props.event.eventData.eventID !== 'number') {
            throw new Error('props.event.eventData.eventID deve esistere e essere un numero intero');
        }

        // controllo che map container esista e sia un elemento html valido
        if (checkMapContainer && (!mapContainer.value || !(mapContainer.value instanceof HTMLElement))) {
            throw new Error('mapContainer non è un elemento HTML valido');
        }

        // controllo se mapContainer ha un id
        if (checkMapContainer && !mapContainer.value.id) {
            throw new Error('mapContainer deve avere un id');
        }

        // setto mapService se non esiste già
        !mapsService && checkMapContainer && (mapsService = new MapService(mapContainer.value.id));

        // controllo che mapService sia un oggetto
        if (typeof mapsService !== 'object') {
            throw new Error('mapsService deve essere un oggetto');
        }

        const { lat, lng, imei } = props.event.eventData;

        return { lat, lng, imei };
    });

    const getTooltipContent = (lat, lng, speed, address) => {
        return `
            <div class="max-w-24 lg:max-w-80 truncate">Lat e Lng: ${lat} , ${lng}</div>
            <div class="max-w-24 lg:max-w-80 truncate">Velocità: ${formatterSpeed(speed)}</div>
            <div class="max-w-24 lg:max-w-80 truncate">Address: ${address}</div>
        `;
    };

    // definisco la funzione per settare la mappa
    const setMap = handleErrorsSilent(async () => {

        // recupero lat e lng dall'evento
        const { lat, lng, imei, speed, address } = await checkExistencesEventData();

        // reset della mappa a priori
        mapsService.resetMap();

        // inizializzo la mappa
        mapsService.initMap(lat, lng, 14);

        // aggiungo il marker 
        mapsService.addMarker(imei, lat, lng, getTooltipContent(lat, lng, speed, address), {
            iconUrl: 'https://cdn.momap.it/app/vehicles/car_on.png',
            iconSize: [70, 70],
            iconAnchor: [35, 35]
        });

        // creo il cerchio intorno al marker
        mapsService.addCircle(imei,lat, lng, 400, {
            color: 'blue',
            fillColor: 'blue',
            fillOpacity: 0.1
        });

    });

    const updatePosition = handleErrorsSilent(async (values) => {

        // recupero lat e lng dall'evento
        const { imei } = await checkExistencesEventData(false);

        // recupero lat, lng, imei, speed e address dai values
        const { lat = null, lng = null, imei: imeiValues = null, speed = null, address = null } = values?.data;

        // controllo che imeiValues esista
        if (!imeiValues || typeof imeiValues !== 'string') {
            throw new Error('imeiValues deve esistere e essere una stringa');
        }

        // controllo che imei esista
        if (!imei || typeof imei !== 'string') {
            throw new Error('imei deve esistere e essere una stringa');
        }

        // controllo che imeiValues sia uguale a imei in modo case insensitive
        if (imeiValues.toUpperCase() !== imei.toUpperCase()) {
            throw new Error('imeiValues e imei devono essere uguali in modo case insensitive');
        }

        // Attivo il lampeggio
        isBlinking.value = true;
        setTimeout(() => isBlinking.value = false, 2000);

        // aggiorno la posizione del marker e del cerchio
        mapsService.moveMarkerAndCircle(imei, lat, lng, 500, true, getTooltipContent(lat, lng, speed, address));
    });

    // definisco la funzione per formattare il numero di telefono
    const getPhoneNumber = (phoneNumber) => {
        try {

            if (!phoneNumber || typeof phoneNumber !== 'string') {
                return phoneNumber;
            }

            // formatto il numero di telefono
            const formattedNumber = `+39${phoneNumber.replace(/\s/g, '')}`;

            return `
                <div class="flex items-center gap-2">
                    <span>${formattedNumber}</span>
                    <a target="_blank" href="tel:${formattedNumber}" class="text-blue-500">Chiama</a>
                    <a target="_blank" href="https://wa.me/${formattedNumber.replace(/[.,\s-]/g, '')}" class="text-green-500">WhatsApp</a>
                </div>
            `;
            
        } catch (error) {
            import.meta.env.VITE_DEVELOPMENT === 'true' && console.error(error);
            return null;
        }
    };
    
    // watch sulla props event per ricaricare la mappa
    watch(() => props.event, async () => await setMap() ); 

    onMounted(async () => {
        
        // setto la mappa
        await setMap();

        // ascolto gli eventi del socket per aggiornare la posizione
        SocketStore.on('moveActiveEvent', (message) => updatePosition(message)); 

    });

    onUnmounted(() => {
        mapsService && mapsService.resetMap();
        SocketStore.off('moveActiveEvent');
    });

</script>

<style scoped>
    @keyframes blink-animation {
        50% {
            opacity: 0;
        }
    }

    .animate-blink {
        animation: blink-animation .5s steps(5, start) 2;
    }
</style>