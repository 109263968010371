export default {
    login: {
        title: 'Login',
        description: 'Login'
    },
    home: {
        title: 'Homepage',
        description: 'Pagina dove potrete vedere statistiche, notifiche e altro'
    },
    clients: {
        title: 'Clienti',
        description: 'Panoramica completa dei clienti'
    },
    users: {
        title: 'Utenti',
        description: 'Panoramica completa degli utenti'
    },
    notFound: {
        title: '404',
        description: 'Pagina non trovata'
    }
}