import { defineStore } from 'pinia';
import { io } from 'socket.io-client';
import Cookies from 'js-cookie';

export const useSocketStore = defineStore('socket', {
  state: () => ({
    socket: null,
  }),
  actions: {

    connect() {

      if (this.socket) {
        return;
      }

      // Creazione del socket
      this.socket = io(import.meta.env.VITE_SOCKET_URL);

      this.socket.on('connect', () => {
        this.isConnected = true;
        import.meta.env.VITE_DEVELOPMENT === 'true' && console.log('Socket collegato');
      });

      this.socket.on('disconnect', () => {
        this.isConnected = false;
        import.meta.env.VITE_DEVELOPMENT === 'true' && console.log('Socket disconnesso');
        this.socket = null;
      });
    },

    disconnect() {
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
      }
    },

    on(event, callback) {
      this.socket && this.socket.on(event, callback);
    },

    off(event, callback) {
      this.socket && this.socket.off(event, callback);
    },

    emit(event, data = {}) {
      const token = Cookies.get('jwt', { domain: import.meta.env.VITE_BASE_URL_COOKIES }) || null;
      this.socket && this.socket.emit(event, { ...data, jwt: token });
    }

  }
});
