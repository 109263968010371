import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

class MapService {
    constructor(mapId) {
        this.mapId = mapId;
        this.map = null;
        this.markers = new Map();
        this.circles = new Map();
    }

    initMap(lat = 51.505, lng = -0.09, zoom = 12) {
        this.map = L.map(this.mapId).setView([lat, lng], zoom);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© OpenStreetMap contributors'
        }).addTo(this.map);
    }

    setView(lat, lng, zoom) {
        this.map.setView([lat, lng], zoom);
    }

    fitBounds(latLngBounds) {
        this.map.fitBounds(latLngBounds);
    }

    addMarker(id, lat, lng, popupText, iconOptions = null) {
        let markerOptions = {};

        iconOptions && (markerOptions.icon = L.icon(iconOptions));
        
        const marker = L.marker([lat, lng], markerOptions).addTo(this.map);
        
        marker.on('click', () => {
            if (marker.getTooltip()) {
                marker.closeTooltip();
            } else if (marker.tooltipContent) {
                try {
                    marker.bindTooltip(String(marker.tooltipContent), {
                        permanent: false,
                        direction: 'top',
                        offset: L.point(0, -30)
                    }).openTooltip();
                } catch (error) {
                    console.error('Error binding tooltip:', error);
                }
            }
        });

        this.markers.set(id, marker);
        return marker;
    }

    setMarkerIcon(id, iconOptions) {
        const marker = this.markers.get(id);
        if (marker) {
            const newIcon = L.icon(iconOptions);
            marker.setIcon(newIcon);
        }
    }

    moveMarker(id, newLat, newLng, duration = 1000, panMap = true, label = null) {
        const marker = this.markers.get(id);
        if (marker) {
            const endLatLng = L.latLng(newLat, newLng);
            marker.slideTo(endLatLng, {
                duration: duration,
                keepAtCenter: false
            });
            if (panMap) {
                this.panTo(newLat, newLng, duration);
            }
            if (label !== null) {
                marker.unbindTooltip().bindTooltip(label, {
                    permanent: true,
                    direction: 'top',
                    offset: L.point(0, -30)
                }).openTooltip();
            }
        }
    }

    removeMarker(id) {
        const marker = this.markers.get(id);
        if (marker) {
            this.map.removeLayer(marker);
            this.markers.delete(id);
        }
    }

    addPolygon(latLngs, options = {}) {
        return L.polygon(latLngs, options).addTo(this.map);
    }

    addCircle(id, lat, lng, radius, options) {
        const circle = L.circle([lat, lng], {
            radius: radius,
            ...options
        }).addTo(this.map);
        this.circles.set(id, circle);
        return circle;
    }

    moveCircle(id, newLat, newLng) {
        const circle = this.circles.get(id);
        if (circle) {
            circle.setLatLng([newLat, newLng]);
        }
    }

    removeCircle(id) {
        const circle = this.circles.get(id);
        if (circle) {
            this.map.removeLayer(circle);
            this.circles.delete(id);
        }
    }

    moveMarkerAndCircle(id, newLat, newLng, duration = 1000, panMap = true, label = null) {
        const marker = this.markers.get(id);
        if (marker) {
            const endLatLng = L.latLng(newLat, newLng);
            marker.slideTo(endLatLng, {
                duration: duration,
                keepAtCenter: false
            });
            if (panMap) {
                this.panTo(newLat, newLng, duration);
            }
            if (label !== null) {
                marker.tooltipContent = String(label);
                if (marker.getTooltip()) {
                    marker.setTooltipContent(marker.tooltipContent);
                }
            }
        }
        this.moveCircle(id, newLat, newLng);
    }

    panTo(lat, lng, duration = 1000) {
        this.map.panTo([lat, lng], {
            duration: duration / 1000,
            easeLinearity: 0.5
        });
    }

    on(event, callback) {
        this.map.on(event, callback);
    }

    off(event, callback) {
        this.map.off(event, callback);
    }

    resetMap() {
        if (this.map !== null) {
            
            // Rimuovo tutti i layer, inclusi i marcatori
            this.map.eachLayer(layer => {
                this.map.removeLayer(layer);
            });

            // Rimuovo tutti i riferimenti ai marcatori
            this.markers.clear();

            // Distruggo l'istanza della mappa
            this.map.remove();

            // Reimposto la variabile map a null
            this.map = null;
        }
    }
}

// Add this utility function outside the class
L.Marker.prototype.slideTo = function(newLatLng, options) {
    const startLatLng = this.getLatLng();
    const startTime = Date.now();
    const duration = options.duration || 1000;

    const animate = () => {
        const now = Date.now();
        const progress = Math.min((now - startTime) / duration, 1);
        const lat = startLatLng.lat + (newLatLng.lat - startLatLng.lat) * progress;
        const lng = startLatLng.lng + (newLatLng.lng - startLatLng.lng) * progress;
        this.setLatLng([lat, lng]);

        if (progress < 1) {
            requestAnimationFrame(animate);
        }
    };

    animate();
};

export default MapService;