// import dei microservizi
import general from '@/i18n/locales/it/general';
import forms from '@/i18n/locales/it/forms';
import headers from '@/i18n/locales/it/headers';
import events from '@/i18n/locales/it/events';
import table from '@/i18n/locales/it/table';

export default { 
    general,
    forms,
    headers,
    events,
    table
};