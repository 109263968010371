import i18n from "@/i18n";
import DateServices from '@/helpers/date';

// Definisco il multilingua
const { t, d } = i18n.global;

// definisco la funzione per formattare la velocitò
export const formatterSpeed = (val = null) => {
    try {

        // controllo se il valore è null oppure non è un numero valido
        if (val === null || isNaN(val)) {
            throw new Error('Invalid value');
        }

        return `${val.toLocaleString('it', { minimumFractionDigits: 1, maximumFractionDigits: 1 })} km/h`;
        
    } catch (error) {
        return t('general.dataNotFound');
    }
}

// definisco la funzione per formattare la data
export const formatterDate = (val = null, mode = 'long') => {
    try {

        // controllo se il valore è null
        if (val === null) {
            throw new Error('Invalid value');
        }

        const formattedData = DateServices.formatDate(val);

        // controllo che non sia null
        if (formattedData === null) {
            throw new Error('Invalid value');
        }

        return d(formattedData, mode);
        
    } catch (error) {
        return t('general.dataNotFound');
    }
}