<template>
    <section class="max-h-[calc(100vh-_6rem)] flex flex-col">

        <!-- Intestazione -->
        <div class="py-1.5 px-2 text-slate-50 rounded-t-lg bg-surface-800 dark:bg-slate-50 dark:text-surface-800">
            <div class="flex items-center justify-start gap-2 text-sm">
                <span class="material-symbols-outlined material-symbols-font-300"> chat </span>
                <span class="font-bold tracking-tight uppercase">Chat</span>
            </div>
        </div>

        <!-- Chat Container -->
        <div :key="messageListKey" ref="scrollPanelRef" class="flex-grow overflow-y-auto overflow-x-hidden px-3 py-1.5 mt-2">
            
            <!-- Visualizzazione dei messaggi -->
            <div v-for="message in messagesList" :key="message" class="mb-4 flex" :class="getMessageAlignment(message)">
                <div>
                
                    <!-- Intestazione messaggio -->
                    <div class="inline-flex gap-1.5 py-2 text-sm tracking-wider">
                        
                        <!-- Sezione del nome -->
                        <span class="capitalize">
                        
                            <!-- Caso in cui è un messaggio di sistema -->
                            <div v-if="systemTypes.includes(message?.messageType)">Sistema</div>

                            <!-- Caso in cui è un mio messaggio -->
                            <div v-else-if="message?.operatorID === user?.userID">Tu</div>

                            <!-- Caso in cui è un messaggio di utente -->
                            <div v-else> {{ message?.operatorName || user?.operatorName }} </div>
                        </span>

                        <span class="text-gray-600 dark:text-gray-400">{{ $d(message?.created_at, 'messageDate') }}</span>

                    </div>

                    <div :class="getMessageBorderClass(message)">
                        <span>{{ message?.messageText }}</span>
                    </div>


                </div>
            </div>
        
        </div>

        <!-- Send Message -->
        <div class="py-3 px-2">
            <Form @submit="sendMessage">
                <Field name="message" rules="required" v-model="message">
                    <InputGroup>
                        <InputText placeholder="Invia un messaggio" v-model="message" />
                        <InputGroupAddon>
                            <button type="submit" class="pi pi-send text-sky-300"></button>
                        </InputGroupAddon>
                    </InputGroup>
                </Field>
            </Form>
        </div>

    </section>
</template>

<script setup>

    // Base imports
    import { ref, computed, onMounted, nextTick, onUnmounted } from 'vue';
  
    // Store imports
    import { useAuthStore } from '@/stores/auth.js';
    import { useSocketStore } from '@/stores/socket';

    // Services imports
    import { handleErrorsSilent } from '@/helpers/errorHandler';

    // Definisco le props
    const props = defineProps({
        messages: {
            type: Array,
            default: null
        }
    });

    // recupero SocketStore
    const SocketStore = useSocketStore();
  
    // Recupero i dati dell'utente
    const user = computed(() => useAuthStore().getUser);

    // Definisco il modello dei messaggi e del messaggio da inviare
    const messagesList = ref(props.messages);
    const messageListKey = ref(0);
    const message = ref(null);

    // Definisco i tipi che sono considerati messaggi di sistema
    const systemTypes = ['info', 'warn', 'error', 'login', 'logout', 'eventAck'];

    // Recupero il riferimento del pannello
    const scrollPanelRef = ref(null);

    // Funzione per ottenere l'allineamento del messaggio
    const getMessageAlignment = (message) => {
        if (systemTypes.includes(message?.messageType)) {
            return 'justify-start';
        }
        return message?.operatorID === user?.value?.userID ? 'justify-end' : 'justify-start';
    }

    // Funzione per ottenere la classe del bordo del messaggio
    const getMessageBorderClass = (message) => {
        const baseClasses = 'font-medium p-3 rounded-full space-x-2 relative';
        return systemTypes.includes(message?.messageType)
            ? `${baseClasses} bg-purple-500 dark:bg-purple-700 !text-white`
            : message?.operatorID === user?.value?.userID ? 
                `${baseClasses} bg-green-500 dark:bg-green-300 dark:text-surface-800` : `${baseClasses} bg-surface-800 dark:bg-surface-50 text-surface-50 dark:text-surface-800`;
    };

    // Funzione per inviare il messaggio
    const sendMessage = handleErrorsSilent(async (values) => {

        // aspetto il caricamento
        await nextTick();
        
        // invio il messaggio
        SocketStore.emit('chatMessage', { messageText: values?.message, messageType: 'message' });

    });

    // Funzione per ottenere i messaggi dal server
    const getMessages = handleErrorsSilent(async (values = null) => {
        
        // controllo che message esista e che sia un oggetto
        if (!values || typeof values !== 'object' || Object.keys(values).length === 0) {
            throw new Error('Invalid message');
        }

        // definisco le chiavi richieste per il messaggio
        const requiredKeys = ['operatorName', 'operatorID', 'messageText', 'created_at', 'messageType', 'messageID'];

        // controllo che ci siano tutte le chiavi richieste
        if (!requiredKeys.every(key => key in values)) {
            throw new Error('Missing required keys');
        }

        // aggiungo il messaggio alla lista
        messagesList.value.push(values);

        // reset del modello
        message.value = null;

    });

    onMounted(() => {
        SocketStore.on('chatMessage', async (message) => await getMessages(message));
    });

    onUnmounted(() => {
        SocketStore.off('chatMessage');
    });

</script>

