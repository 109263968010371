<template>
  
  <!-- Toast component -->
  <ToastWrapper />

  <!-- Dinamic Dialog component -->
  <DynamicDialogWrapper />

  <!-- Main component -->
  <div class="flex flex-col h-screen">
    <ToolBar/>
    <div class="grow py-4 px-2 lg:px-6 overflow-y-auto overflow-x-hidden">
      <RouterView/>
    </div>
  </div>

</template>

<script setup>

  // based imports
  import { onMounted } from 'vue';

  // services imports
  import { useDark } from '@vueuse/core';

  // import Toast component
  import ToastWrapper from '@/components/reusable/ToastWrapper.vue';

  // import Dinamic Dialog component
  import DynamicDialogWrapper from '@/components/reusable/DinamicDialogWrapper.vue';

  // navigations components imports
  import ToolBar from '@/components/navigations/ToolBar.vue';

  // store imports
  import { useSocketStore } from '@/stores/socket';

  // hoock di caricamento
  onMounted(() => {
    
    // setto il tema
    useDark();

    // connetto al socket
    useSocketStore().connect();

  });

</script>