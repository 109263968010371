import Middleware from '@/router/middleware.js';

export default {
    mounted(el, binding) {

        const { role, operator, useCanDirective } = extractRoleAndOperator(binding.value);

        if(useCanDirective === false) {
            return;
        }

        // Verifica dei permessi e rimozione dell'elemento se non autorizzato
        if (!Middleware.hasPermission(role, operator)) {
            el.parentNode?.removeChild(el);
        }

        // Salvataggio dei dati nel elemento per l'uso nello smontaggio
        el._canBinding = { role, operator };
    },

    unmounted(el) {
        el._canBinding && delete el._canBinding;
    }
};

// Funzione per estrarre role e operator con valori di default
function extractRoleAndOperator(value) {
    if (typeof value === 'object' && value !== null) {
        return { 
            role: value.role || 'operator',
            operator: value.operator || 'gte',
            useCanDirective: value.useCanDirective != null ? value.useCanDirective : true
        };
    }
    return { role: value || 'operator', operator: 'gte', useCanDirective: true };
}
